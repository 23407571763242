import React from "react";
import { Col, Container, Row } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "transparent",
        }}
        className="section pb-2"
      >
        <Container fluid>
          <Container>
            <Row>
              <Col lg={6} className={``}>
                <h4 className=" text-700 text-white">
                  <b>Exibex</b> remains steadfast in its commitment to celebrate and recognize the
                  extraordinary accomplishments of innovators, pioneers, and bold trailblazers who
                  push the boundaries of their fields. We applaud those who break the mould, set new
                  trends, and spark change.
                  <br />
                  <br />
                  The Finnovex Awards serve as a powerful tribute to our unwavering commitment to
                  recognizing and applauding the exceptional endeavours of individuals,
                  institutions, and service providers in the ever-changing arena of financial
                  services. We are dedicated to paying homage to the visionary pioneers who are
                  driving transformative shifts in the financial landscape, propelling the industry
                  into new horizons of innovation and progress.
                  <br />
                  <br />
                  The Finnovex Award is more than an accolade—it's an emblem embodying unparalleled
                  innovation that paves a path toward unwavering excellence. With unwavering pride,
                  we honour and celebrate exceptional contributions within the banking and financial
                  services arena. Our purpose is singular: to recognize organisations and
                  individuals who exemplify consistent, outstanding performance. These prestigious
                  awards forge an uncompromising standard of excellence, simultaneously serving as
                  an unequivocal platform for financial institutions to showcase their prowess and
                  pinpoint the vanguards of the industry.
                </h4>
                <br />
              </Col>
              <Col lg={6} className={`mb-3 `}>
                <img
                  src={require("assets/images/awards/DSC_0435.JPG")}
                  width="100%"
                  alt="awards"
                  style={{
                    borderRight: "10px solid #c0dea7",
                    borderTop: "10px solid #c0dea7",
                  }}
                />
              </Col>

              {content.map((data, index) => (
                <Col lg={3} key={index} className="stat-div border">
                  <div className="d-flex align-items-center" style={{ minHeight: "125px" }}>
                    <h5 className="text-700 mt-0 text-white">{data}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Excellence in Mobile Banking",
  "Excellence in Digital Banking",
  "Excellence in Fintech",
  "Excellence in Payments",
  "Excellence in Cyber Security",
  "Excellence in Customer Experience Enhancement",
  "Excellence in InsurTech",
  "Excellence in Open Banking",
  "Excellence in Cloud Banking",
  "Outstanding Contribution to Financial Inclusion Initiative",
  "Outstanding Contribution to Sustainable Finance",
  "Outstanding Contribution to Strategic National Development Initiatives",
  "Outstanding Contribution to Fintech Initiatives",
  "Finnovator of the Year, Qatar",
  "Finfluencer of the Year, Qatar",
  "Financial Women Trailblazer of the Year",
  "CEO of the Year",
  "COO of the Year",
  "CDO of the Year",
  "CIO of the Year",
  "CTO of the Year",
  "CFO of the Year",
  "CISO of the Year",
];
