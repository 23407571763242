export const whosponsor = [
  {
    id: 1,
    title: "Thought Leadership",
    description: [
      "As a sponsor at Finnovex, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.",
    ],
    location: "left",
    image: "1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY",
  },
  {
    id: 2,
    title: "PREMIUM BRANDING",
    description: [
      "We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ",
      "As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, media partnerships and social media like how we have built within a year brand like Finnovex Global Series in Qatar and Africa.Find out more about team working on Finnovex who successfully executed Finnovex Global Series www.finnovex.com",
    ],
    location: "right",
    image: "1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9",
  },
  {
    id: 3,
    title: "FEATURED NETWORKING EVENTS / FACE TIME:",
    description: [
      "Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.",
      "Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events,we have successfully Introduced more than 100+ Banks,FI meetings with our sponsors find out more on our YouTube Channel.",
    ],
    location: "left",
    image: "1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ",
  },
];

export const sponsors = [
  {
    image: "backbase.png",
    type: "OFFICIAL ENGAGEMENT BANKING SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://www.backbase.com/",
  },
  {
    image: "threatLocker.jpg",
    type: "PLATINUM SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://threatlocker.com/",
  },
  {
    image: "kissflow.jpg",
    type: "GOLD SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://kissflow.com/",
  },

  {
    image: "infosys.jpg",
    type: "STRATEGIC PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.edgeverve.com/finacle/",
    a: 6,
  },
  {
    image: "azentio.jpg",
    type: "OFFICIAL TECHNOLOGY PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.azentio.com/",
    a: 6,
  },
  {
    image: "comforte_logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.comforte.com/",
  },
  {
    image: "Concerto-Logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.concertosoft.com/",
  },
  {
    name: "Neosoft",
    image: "neosoft.png",
    type: "NETWORKING SPONSOR",
    link: "http://www.neosofttech.com/",
    grid: 6,
    text: "NeoSOFT is an SEI-CMMI Level-5 and ISO 9001:2008 certified global IT consulting & software solutions provider with 2000+ software consultants working full time across 7 Delivery Centers. Established in 1996, NeoSOFT is headquartered in Mumbai, India with offices in the USA, UK, Dubai, Italy, Germany, Japan & Australia. Our commitment to quality and 23+ years of experience has made us serve over 1500+ clients across 50+ countries with 85% client retention. They build cohesive technology solutions for the World’s Leading Enterprises, SMEs & Startups and ISV's We cater to more than 22+ Industries like  Banking & Finance - E-Commerce & Retail - Education & E-Learning - Government/ Semi-Government Healthcare - Insurance - IT & Consulting - Manufacturing - Media & Entertainment to name a few.",
  },
  {
    image: "microsoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.microsoft.com/",
  },
  {
    image: "oracle.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },
  {
    image: "pwc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },
  {
    image: "EY.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.ey.com/en_in",
  },
  {
    image: "tcs.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.tcs.com/",
  },
  {
    image: "expo2020.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.expo2020dubai.com/",
  },
  {
    image: "edb.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bahrainedb.com/",
  },
  {
    image: "difc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.difc.ae/",
  },

  {
    image: "oxford.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
  },
  {
    image: "rakbank.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },

  {
    image: "smartstream.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartstream-stp.com/",
  },
  {
    image: "temenos.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.temenos.com/",
  },
  {
    image: "avaya.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.avaya.com/en/",
  },

  {
    image: "bankserv.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bankservafrica.com/website/",
  },
  {
    image: "beingcrypto.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://beincrypto.com/",
  },
  {
    image: "comviva.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.comviva.com/",
  },
  {
    image: "consdata.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.consdata.com/en/",
  },
  {
    image: "daon.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.daon.com/",
  },
  {
    image: "darktrace.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "darktrace",
  },

  {
    image: "fireeye.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fireeye.com/",
  },
  {
    image: "freshworks.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.freshworks.com/",
  },
  {
    image: "fss.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fsstech.com/",
  },
  {
    image: "iib.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://iib.gov.in/",
  },
  {
    image: "infobip.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infobip.com/",
  },
  {
    image: "infrasoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infrasofttech.com/",
  },
  {
    image: "ManageEngine.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.manageengine.com/",
  },

  {
    image: "newgen.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://newgensoft.com/home-us/",
  },
  {
    image: "onespan.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.onespan.com/",
  },

  {
    image: "piotech.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },

  {
    image: "recorded.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.recordedfuture.com/",
  },
  {
    image: "scventure.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://scventures.io/",
  },

  {
    image: "smartmessage.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartmessage.com/",
  },

  {
    image: "turbonomic.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.turbonomic.com/",
  },
  {
    image: "yethi.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://yethi.in/",
  },
  {
    name: "network international",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "network.jpg",
  },
  {
    name: "arabnet",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "arabnet.jpeg",
  },
  {
    name: "triwire",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "tripwire.jpeg",
  },
  {
    name: "fintechgalaxy",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "fintechgalaxy.jpeg",
  },
  {
    name: "creatio ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "creatio.png",
  },
  {
    name: "confluence",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "confluent.jpg",
  },
  {
    name: "abaka",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "abaka.jpg",
  },
  {
    name: "payoneer ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "Payoneer.jpeg",
  },
  {
    name: "Unlockbc",
    type: "Media Partner",
    grid: 3,
    link: "http://www.unlock-bc.com/",
    image: "unlock.png",
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const live = [
  {
    id: 1,
    link: "Marketing-Strategies-For-Fintech-In-2020",
    description: "Marketing Strategies For Fintech In 2020",
    content:
      "Getting busier and more competitive by the day, it's important for those involved to consider strategies for how to stand out and sustain business.",
    image: "1.png",
  },
];

export const speakers = [
  {
    name: "Fernando Plaza",
    title: "Chief Digital Officer",
    company: "Arab National Bank, Saudi Arabia",
    image: "Fernando Plaza.jpg",
    companyImage: "Arab National Bank, Saudi Arabia .jpg",
  },
  // {
  //   name: "Javier Rodriguez Soler",
  //   title: "Global Head of Sustainability",
  //   company: "BBVA USA (Banco Bilbao Vizcaya Argentaria)",
  //   image: "Javier Rodriguez Soler.jpg",
  //   companyImage: "BBVA USA .png",
  // },

  {
    name: "Faisal Saber Al Yousef ",
    title: "Managing Director KSA and UAE",
    company: "Taptap Send",
    image: "faisal1.jpg",
    companyImage: "taptap.png",
  },
  // {
  //   name: "Arif Ahmed",
  //   title: "SVP Artificial Intelligence and Analytics",
  //   company: "U.S Bank",
  //   image: "Arif Ahmed.jpg",
  //   companyImage: "",
  // },
  {
    name: "Abdullah Faisal Biary",
    title: "Chief Information Security Officer",
    company: "United Cooperative Assurance, Saudi Arabia",
    image: "abdullah.jpeg",
    companyImage: "",
  },
  // {
  //   name: "Nawaf Kably",
  //   title: "Chief Human Resources Officer",
  //   company: "Gulf International Bank, Saudi Arabia",
  //   image: "Nawaf Kalby.jpg",
  //   companyImage: "gib.png",
  // },

  {
    name: "Dr. Ahmed Darwish Elsayed ",
    title: "Head of Digital Delivery",
    company: "Bank AlBilad, Saudi Arabia",
    image: "Ahmed Darwish Photo.jpg",
    companyImage: "albilad.jpg",
  },
  // {
  //   name: "Saeed Assiri",
  //   title: "Acting Chief Digital Officer",
  //   company: "SABB, Saudi Arabia",
  //   image: "speaker.png",
  //   companyImage: "SABB .png",
  // },
  {
    name: "Dr. Jassim Haji",
    title: "President-Artificial Intelligence Society",
    company: "International Group of Artificial Intelligence, Bahrain",
    image: "Dr. Jassim Haji.jpg",
    companyImage: "cropped-Artificial-intelligence-logo.png",
  },

  {
    name: "Sultan Al-Hamidi",
    title: "Executive Vice President",
    company: "Social Development Bank(SDB), Saudi Arabia",
    image: "Sultan Al-Hamidi.jpg",
    companyImage: "sdb.jpg",
  },
  // {
  //   name: "Muhammad Tariq Ahmed Khan",
  //   title: "Senior Vice President - Head of Cyber Security",
  //   company: "Riyad Bank, Saudi Arabia",
  //   image: "Muhammad Tariq Ahmed Khan.jpg",
  //   companyImage: "Riyad Bank.png",
  // },

  {
    name: "Alaa Al-Mashhadi",
    title: "Chief Business Development Officer",
    company: "Saudi Credit Bureau - SIMAH",
    image: "Alaa.jpeg",
    companyImage: "Saudi Credit Bureau - SIMAH.jpg",
  },

  // {
  //   name: "Fraser Matthews",
  //   title: "Head of Digital Strategy",
  //   company: "Banque Saudi Fransi",
  //   image: "Fraser Mathews.jpg",
  //   companyImage: "bsf.jpg",
  // },
  {
    name: "Ali Alomran",
    title: "Financial Technology Expert",
    company: "Saudi Arabia",
    image: "Ali Alomran.jpg",
    companyImage: undefined,
  },
  {
    name: "Hashim M. Al Tamimi",
    title: "Chief Operating officer",
    company: "National Financial Services Company, Saudi Arabia",
    image: "hashim.jpg",
    companyImage: undefined,
  },
  // {
  //   name: "Eng. Hamza A. Alsaktawi",
  //   title: "Head, Innovation and Knowledge Management",
  //   company: "ICD - ISDB Group, Saudi Arabia",
  //   image: "hamza.jpg",
  //   companyImage: "",
  // },
  // {
  //   name: "Mamdoh Alzhrani",
  //   title: "Senior Vice President - Cyber Security",
  //   company: "technology and technical leader",
  //   image: "Mamdoh Alzhrani.jpg",
  //   companyImage: "",
  // },
  // {
  //   name: "Wael Fattouh",
  //   title: "Chief Information Security Officer",
  //   company: "Bank Aljazira",
  //   image: "Wael Fattouh.jpg",
  //   companyImage: "jazira.jpeg",
  // },
  // {
  //   name: "Abdullah Ali Al-Furaiji ",
  //   title: "Chief Digital Officer",
  //   company: "AlRajhi Bank",
  //   image: "Abdullah Furaiji.jpg",
  //   companyImage: "rajhi.png",
  // },
  // {
  //   name: "Grant Niven",
  //   title: "Head of Group Digital",
  //   company: "Banque Saudi Fransi",
  //   image: "Grant Niven.jpg",
  //   companyImage: "bsf.jpg",
  // },
  // {
  //   name: "Bandar Al Qubisy",
  //   title: "Head Of Business Development",
  //   company: "Banque Saudi Fransi",
  //   image: "Bandar Al Qubisy.jpg",
  //   companyImage: "bsf.jpg",
  // },

  // {
  //   name: "Amol K Bhaguna",
  //   title:
  //     "SVP, Head of Corporate Technology, Innovation and Change Management",
  //   company: "Riyad Bank",
  //   image: "speaker.png",
  //   companyImage: "Riyad Bank.png",
  // },
  // {
  //   name: "Amaar Khan",
  //   title: "Chief Information Officer- Asia Pacific",
  //   company: "BNY Mellon",
  //   image: "speaker.png",
  //   companyImage: "",
  // },

  // {
  //   name: "Ben Jenkins",
  //   title: "Senior Solutions Engineer",
  //   company: "ThreatLocker",
  //   image: "ben Jenkins.jfif",
  //   companyImage: "threatlocker.jpg",
  // },

  {
    name: "Fawaz Al-Fraiji",
    title: "Vice Chairman ",
    company: "Quara Finance",
    image: "Fawaz Al-Fraiji.jpg",
    companyImage: "Quara Pay Saudi Arabia.png",
  },

  {
    name: "Ahmad Ghandour",
    title: "Managing Director",
    company: "Backbase Saudi Arabia",
    image: "Ahmad Backbase.png",
    companyImage: "backbase.png",
  },

  {
    name: "Manish N",
    title: "Head of Banking Solutions and Technology",
    company: "Kissflow",
    image: "Manish.jpg",
    companyImage: "kissflow.png",
  },

  {
    name: "Amru Kotb",
    title: "Senior Vice President Sales",
    company: "Comforte",
    image: "Amru Kotb.jpg",
    companyImage: "comforte_logo.png",
  },
  {
    name: "Osama Bukhari",
    title: "Head of Banking ",
    company: "Committee International Chamber Of Commerce, Saudi Arabia",
    image: "OB1 Saudi Headshot.jpg",
    companyImage: undefined,
  },
  {
    name: "Giorgio Torre ",
    title: "Technology Evangelist",
    image: "Torree.jpg",
    companyImage: undefined,
  },

  {
    name: "Sridhar Iyer,",
    title: "Group Chief Digital Officer",
    company: "Gulf International Bank",
    image: "sridhar.jpg",
    companyImage: undefined,
  },

  {
    name: "Sudipt Shah",
    title: "Sudipt Shah Founder & CEO ",
    company: "Digital of Things / User Q",
    image: "Sudipt.jpg",
    companyImage: undefined,
  },
  {
    name: "Dr Sobhi Sulieman Agha",
    title: "Group Chief Marketing Officer",
    company: "SAB Investment ",
    companyImage: undefined,
    image: "sobhi21.jpg",
  },
  {
    name: "Mohammed Al- Abdullah",
    title: "VP / Head of Data Engineer Section",
    company: "Riyad Bank",
    companyImage: "riyadh.png",
    image: "Mohammed Al-Abdullah Personal Photo.jpg",
  },
  {
    name: "Rami Roukoss",
    title: "Manager - Presales & Product Innovation, Global Islamic Banking",
    company: "Azentio Software",
    companyImage: "azentio.png",
    image: "Photo - Azetnio-Rami Roukoss.jpg",
  },
  {
    name: "Lana Mezher",
    title: "Senior Manager - Presales & Product Innovation,",
    company: "Azentio Software",
    companyImage: "azentio.png",
    image: "Photo - Azentio-Lana.jpg",
  },
  {
    name: "Jatinder Bedi",
    title: "Relationship Director & Head, Banking Solutions – Middle East, Africa & America",
    company: "Azentio Software",
    companyImage: "azentio.png",
    image: "Jatinder.jpg",
  },
  {
    name: "Neil Sheth",
    title: "Founder",
    company: "Writefully",
    image: "Neil Pic Light.jpg",
    companyImage: undefined,
  },
];

export const pastspeakers = [];
