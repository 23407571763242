import React, { useEffect } from "react";
import anime from "animejs";

function AnimeBackground() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.9, .1, .2, .3)",
      duration: 1000,
      delay: function (el, i) {
        return i * 150;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: "3rem",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: -100,
        marginLeft: "auto",
        // marginRight: "auto",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1408 792"
        style={{
          fill: "none",
          stroke: "#d2a822cc",
          strokeWidth: 1,
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="st0"
          strokeWidth={5}
          d="M1079.5,186.3l3.7,6.3l-0.6,7.7l-6.8,3.7l-0.2,3.4l-2.4,4.2l-7.5-1.8l-0.8,2.3l3.4,4.9l13.7,4.9l1.3-3.1
	l1.2-5.1l3.8,6.7l-1.2,8.5l-2.2,3.2l-9.9,2.4l-1.5-4.9l-7.5-1.4l-2.9,2.2l-2,1.5l0.1,2.1v1.3l0.5,0.2l0.8,0.3l4.8,1.6l2.6,6.9
	l2.3,1.4l2.1-0.5l1.7,6l-3.2,5.1l-2.9,1.1h-1.2l-6.7,0.1l-3.4,5l0,0l-0.3,10.2l0.8,8l-5.4,8.8l-0.4,0.6l0.2,0.4l3.5,7.8l2.4,1.6
	l2.1,6.6l4.7,4.7l3.2,9l0.5,9.3l3,13.7l0.7,12.2l1.4,3.9l0.5,1.1l0.8,1.9l-1.7,0.7l-4.4,1.7l2.5,3.7l1.9,2.9l10.4,0.4l2.5-1.5
	l6.5,2.5l1.1,0.4l9.6,2.9l-3.3,4.8l-1.9,2.8l4.8,10.3l0.8,1.9l1.9,4.6l-1.6,9l-0.3,4.1l3,7.2l1,8.3l0.2,7.2l-2.1,12.4l2.3,7.1l2.1,4
	l0.9,8.9l-4.8,7.3l-7.8,0.4l0.4,5.6l1.6,7l-3.7,8.1l-2,4.5l-7.5,10.6l-13.3,9.9l0.6,11.9l-1.8,4.1l-2,4.4l0.9,7.7l-2.8,10.5l-9.3,8
	l2,11l-1.3,6.1l-3.4,6.6l-8.1,5.8l-7.8,0.4h-0.5l0.2,1.1l1.2,5.7l-1.4,5.3l-1.9,4.9l-2.9,5l-0.8,0.5l-5.4,3.6l-0.3-5.3l-3.7-6.1
	l2.8-2.4l5.7-5.2l0.5-5.9l-3.5-5.1l-3.3,1.8l-2.2,0.3l-5.4,0.7l-5.6-0.8l-1.3-10l-3.2,0.9l-0.4,4.1l-4.8,3.6l-4.1,0.6l-0.7,0.1
	l-5.3,4.4l-3-0.6v6l3.2,3.2l2.5-0.3l10.4-1.2l3.8,1.6l1.3,1.2l3.6,3.5l3.4,3.1l-1.6,4.6l0.6,5.7l-1.4,12.5l-27.7,8.9l-25.6,6.9
	L953,659l-12.2-3.4l-18.9-5.2l-6.9-4.3l-10.1-6.3l-4.7-2.9l-11.5-19.5l-10.5-17.8l-8.7-14.8l-0.4-0.5l-6.7-9.6l2.9-3.8l15.9-7.7
	l-2.2-13.6l-4.4-6.6l-0.5-9.5l0.4-3.1l0.9-7.7l-4.9-12.4l-1.3-3.9l-7.1-6.1l-0.4-0.3l-5-5.5l-0.2-0.2l-4-9l1.6-12.4l2.9-2
	c-0.3-1.1-3.7-13.2-3.7-13.2l-0.3-4.3l-4.6-5.5l-4.9-5.9l-1.2-11.5l-1.9-6.4l-1.7-10.8l1.1-15.9l0.6-8.4l-2.8-7.8l-1.7-17.9
	l-0.7-12.9l-0.5-8.9l-3.3-3.4l-1-1.1l0.7-6.7l0.9-5.6l1.4-6.5l-1.1-6.5l-0.5-3.6l5.1-5.3l4.1-1.2l1.5-0.4l3,5.9l1.4,2.8l3.8,5.4
	l0.2,4.2l0.3,5.8l2.2-1l5.8-2.4l-1.9-7.7l-3-6.5l-1.8-7l-2.6,1.3l-0.9-2.6l3.2-9.9l1.3-6.5l0,0l0.6-2.9l-2.6-3.3l-3.1,0.2l-6.6,0.4
	l-1.6-2l7.5-5.5l6.1-0.3l3,2.1l0.7,0.5l2.3-3L858,267l-2.1-2.7l-0.9-3.6l4.2,1.5l5.9,2.1l0.6,10.3l0.4,8l-1.9,6.3l-1,5l4.4,9.7
	l1.9,0.3l0.2,4l-1.5,6.1l2.4,3.2l6.6-2.8l1.2-10.7l1.2-5.3l0,0l-0.4-6.8l-3-9.6l3.3-4.8l3.3-7.6l3.5,2.8l1.2,1.8l0.1,1.8l6.9-3
	l3.2-9.8l-3.5,1.2l-6.5,0.4l-9.5-0.7l-4.6-9.1l-4.4-14.4l0.8-13.1l4.7-1L872,211l6.2-8.9l0.4,0.2l6.2,3.8l2.2,6.7l0.7,4.3l7.1-5.6
	l1.7-8l-0.5-8.9l-5.7-2.1l-3-8.8l1.4-13.7l5.9-0.9l0.1-15.5v-3.5l0.9-4.1l1.8-3.5l4.9,3.5l1.7-2l3-6.5l0.5-4.9l0.7-6.7l1-8.6
	l6.2-3.1l1.4-0.7l5.4-3.2l10.2-2.5l10.9-9.6l0.4-0.3l3.5-6.2l2.4-4.2l9.8-5.5l9.1-0.5l4.3-5.7l7.1,0.8l4.2,0.4l0.9,0.1l14.5,11.4
	l4.8,14.2l0.4,7.2l10.4,10.5l4.4,2.1l2.3,12l1.5,4.8l2.5,1.9l5.9,4.5l15.7-1.2l11.6-0.5l6.6,5.1l3.7,2.8c3.3,7.2,6.5,14.5,9.6,21.8
	L1079.5,186.3z"
        />
        <g>
          <g>
            <g>
              <path
                class="st0"
                d="M1036.1,621.2c2-7.1,4-14.3,5.8-21.5l1.2,5.7l-1.4,5.3l-1.9,4.9l-2.9,5L1036.1,621.2z"
              />
              <path
                class="st0"
                d="M1073.9,538l-2,4.4l0.9,7.7l-2.8,10.5l-9.3,8l2,11l-1.3,6.1l-3.4,6.6l-8.1,5.8l-7.8,0.4
				c2.2-8.6,4.2-17.3,6-26C1057.2,561.2,1065.7,549.8,1073.9,538z"
              />
              <path
                class="st0"
                d="M1098.1,413.4c1.5,28,1.5,55.8-0.1,83.7l-2,4.5l-7.5,10.6l-13.3,9.9l0.6,11.9l-1.8,4.1
				c-8.2,11.7-16.8,23.2-25.8,34.5c5.2-27.6,8.8-55.1,10.8-83.1C1073.7,464.8,1086.6,439.7,1098.1,413.4z"
              />
              <path
                class="st0"
                d="M1014.4,610.3c-7.5,7.8-15.2,15.4-23.1,22.9c2.6-8.2,5-16.4,7.3-24.6"
              />
              <path
                class="st0"
                d="M1027.1,596.7c7.2-7.8,14.1-15.8,21-24l0,0c0,0,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2c5.2-27.6,8.8-55,10.8-82.9
				c-14.6,23.2-30.4,45-47.7,66.2c-2.7,13.6-5.7,26.9-9.2,40.2"
              />
              <path
                class="st0"
                d="M1011.2,555.6c17.4-21.2,33.1-43,47.7-66.2c1.4-28,1.1-55.6-0.8-83.6c-11.2,24.7-23.7,48.1-37.9,71.2
				C1018.8,503.5,1015.8,529.4,1011.2,555.6z"
              />
              <path
                class="st0"
                d="M956.7,612.1c19.4-17.8,37.4-36.5,54.6-56.5c4.6-26.1,7.5-52.1,9-78.6c-14.1,21.6-29.3,42-46,61.7
				C970.1,563.6,964,587.9,956.7,612.1z"
              />
              <path
                class="st0"
                d="M974.3,538.7c16.7-19.7,31.8-40.1,46-61.7c0.9-26.4,0.2-52.4-1.9-78.7c-10.8,22.9-22.8,45.1-36.8,66.3
				C980.6,489.5,978.1,514,974.3,538.7z"
              />
              <path
                class="st0"
                d="M922.1,591c18.5-16.5,35.8-33.8,52.3-52.3c3.8-24.7,6.2-49.1,7.3-74.1c-13.6,20-28.2,38.9-44.2,57.1
				C933.6,545.2,928.5,568.1,922.1,591z"
              />
              <path
                class="st0"
                d="M937.4,521.7c16-18.2,30.5-37.1,44.2-57.1c0.4-24.8-0.7-49.2-3.1-73.9c-10.6,21.4-22.3,41.7-35.6,61.5
				C942.4,475.6,940.5,498.5,937.4,521.7z"
              />
              <path
                class="st0"
                d="M887.5,569.9c17.6-15.1,34.1-31.1,49.9-48.1c3.2-23.2,5-46.2,5.5-69.6c-13.1,18.5-27.1,35.9-42.4,52.6
				C897.3,526.8,893,548.3,887.5,569.9z"
              />
              <path
                class="st0"
                d="M900.5,504.8c15.3-16.7,29.3-34.1,42.4-52.6c-0.1-23.2-1.5-46-4.3-69.1c-10.3,19.6-21.4,38.7-34.4,56.7
				C904.5,461.6,902.9,483.2,900.5,504.8z"
              />
              <path
                class="st0"
                d="M904.2,439.8c0.3,21.8-1.2,43.4-3.7,65c-8.5,8.8-17.2,17.4-26.2,25.6l0.9-7.7l-4.9-12.4l-1.3-3.9l-7.1-6.1
				c0.6-4.2,1.1-8.3,1.6-12.5C878.3,473,891.9,456.8,904.2,439.8z"
              />
              <path
                class="st0"
                d="M863.5,487.9c14.8-14.9,28.4-31.1,40.6-48.1c-0.2-21.5-2.1-43-5.4-64.2c-10.1,18.1-21,35.1-33.3,51.8
				C865.9,447.7,865.3,467.6,863.5,487.9z"
              />
              <path
                class="st0"
                d="M865.5,427.3c0.5,20.3-0.2,40.3-1.9,60.5c-2.3,2.3-4.6,4.5-7,6.7l-0.2-0.2l-4-9l1.6-12.4l2.9-2
				c-0.3-1.1-3.7-13.2-3.7-13.2l-0.3-4.3l-4.6-5.5C854.2,441.2,859.9,434.4,865.5,427.3z"
              />
              <path
                class="st0"
                d="M858.9,367.9c3.3,19.8,5.5,39.4,6.6,59.4c-5.6,7-11.2,13.9-17.1,20.5l-4.9-5.9l-1.2-11.5l-1.9-6.4l-1.7-10.8
				l1.1-15.9C846.6,387.8,852.9,378,858.9,367.9z"
              />
              <path
                class="st0"
                d="M1105.2,409.6l-0.3,4.1l3,7.2l1,8.3l0.2,7.2l-2.1,12.4l2.3,7.1l2.1,4l0.9,8.9l-4.8,7.3l-7.8,0.4l0.4,5.6
				l1.6,7l-3.7,8.1c1.6-28,1.6-55.7,0.1-83.7c2.4-5.8,4.6-11.6,6.8-17.4l1.9,4.6L1105.2,409.6z"
              />
              <path
                class="st0"
                d="M1101.2,381.1l-1.9,2.8l4.8,10.3l0.8,1.9c-2.2,5.8-4.5,11.6-6.8,17.4c-1-13.6-2.4-27-4.1-40.5l1.1,0.4
				l9.6,2.9L1101.2,381.1z"
              />
              <path class="st0" d="M1076.1,362.5l-1.7,0.7c0.3-0.9,0.6-1.7,0.9-2.6L1076.1,362.5z" />
              <path
                class="st0"
                d="M1093.9,372.9c1.7,13.4,3.1,26.9,4.1,40.5c-11.5,26.3-24.3,51.4-39.1,76c1.4-28,1.1-55.6-0.8-83.6
				c5.2-12.3,10-24.7,14.4-37.2l1.9,2.9l10.4,0.4l2.5-1.5L1093.9,372.9z"
              />
              <path
                class="st0"
                d="M1061.4,306.8l4.7,4.7l3.2,9l0.5,9.3l3,13.7l0.7,12.2l1.4,3.9l0.5,1.1c-0.3,0.9-0.6,1.7-0.9,2.6l-4.4,1.7
				l2.5,3.7c-4.4,12.5-9.2,24.9-14.4,37.2c-2.5-28.1-6.6-55.6-12.4-83.2c2.8-10.6,5.3-21.2,7.5-31.9l3.5,7.8l2.4,1.6L1061.4,306.8z"
              />
              <path
                class="st0"
                d="M1053.6,289.7c1.9-8.9,3.5-17.9,5-26.9l-0.3,10.2l0.8,8L1053.6,289.7z"
              />
              <path
                class="st0"
                d="M1062.2,237.6c2.2,6.7,4.4,13.3,6.4,20l-6.7,0.1l-3.4,5C1059.9,254.4,1061.1,246,1062.2,237.6z"
              />
              <path
                class="st0"
                d="M1020.3,477c14.3-23,26.7-46.5,37.9-71.2c-2.5-28.1-6.6-55.6-12.4-83.2c-7.6,26-16.6,50.9-27.5,75.6
				C1020.5,424.6,1021.2,450.6,1020.3,477z"
              />
              <path
                class="st0"
                d="M1018.3,398.2c10.9-24.7,20-49.7,27.5-75.6c-6.4-27.9-14.3-55.1-23.8-82.1c-3.8,27.1-9.2,53.3-16.6,79.6
				C1011.3,346,1015.5,371.9,1018.3,398.2z"
              />
              <path
                class="st0"
                d="M981.6,464.6c14-21.2,25.9-43.4,36.8-66.3c-2.8-26.4-7-52.2-12.8-78.1c-7.5,24.2-16.1,47.7-27,70.5
				C980.9,415.3,981.9,439.8,981.6,464.6z"
              />
              <path
                class="st0"
                d="M978.5,390.7c10.9-22.8,19.6-46.4,27-70.5c-6.4-26.1-14.2-51.5-23.5-76.7c-4,25.2-9.2,49.9-16.8,74.2
				C971,341.9,975.4,366,978.5,390.7z"
              />
              <path
                class="st0"
                d="M942.9,452.2c13.2-19.8,25-40.1,35.6-61.5c-3.1-24.7-7.5-48.8-13.3-73c-7.5,22.5-16.2,44.1-26.5,65.4
				C941.4,406.1,942.8,429,942.9,452.2z"
              />
              <path
                class="st0"
                d="M938.6,383.1c10.4-21.3,19.1-42.9,26.5-65.4c-6.5-24.3-14.2-47.9-23.3-71.3c-4.2,23.5-9.8,46.2-17.1,68.9
				C930.7,337.7,935.3,360.1,938.6,383.1z"
              />
              <path
                class="st0"
                d="M904.2,439.8c13.1-17.9,24.2-37,34.4-56.7c-3.3-23-7.9-45.4-13.8-67.9c-7.4,20.7-15.6,40.9-26,60.3
				C902,396.7,904,418.3,904.2,439.8z"
              />
              <path
                class="st0"
                d="M898.7,375.5c10.4-19.3,18.7-39.6,26-60.3c-6.5-22.5-14.1-44.3-23-65.9c-4.4,21.6-9.6,42.8-17.3,63.5
				C890.6,333.3,895.5,354.3,898.7,375.5z"
              />
              <path
                class="st0"
                d="M865.5,427.3c12.3-16.7,23.2-33.8,33.3-51.8c-3.2-21.2-8.1-42.2-14.3-62.7c-7.4,19.1-15.7,37.2-25.6,55.2
				C862.2,387.7,864.4,407.3,865.5,427.3z"
              />
              <path
                class="st0"
                d="M853.6,282.2c0.1-0.3,0.2-0.5,0.2-0.8c0,0,0,0,0,0c1-3.3,1.9-6.5,2.8-9.8c0,0,0-0.1,0-0.1
				c0-0.1,0-0.1,0.1-0.2"
              />
              <path
                class="st0"
                d="M858,267c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2c0.4-1.5,0.7-2.9,1.1-4.4c0-0.1,0-0.1,0-0.2l0,0l5.8,2.1
				l0.6,10.3l0.4,8l0,0l-1.9,6.3l-1,5l0,0.1l4.4,9.6l1.9,0.3l0.2,4l0,0l-1.5,6.1l0,0.1l2.4,3.1l6.6-2.8l1.2-10.7l0.3-1.1l0-0.1
				l1.1-4.1c1.6,4.8,3.2,9.7,4.6,14.6c-7.4,19-15.8,37.1-25.5,55c0,0.1-0.1,0.1-0.1,0.2c-3.9-19.6-8.8-38.5-14.8-57.5
				c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0.4-1.1,0.8-2.2,1.2-3.3c0,0,0-0.1,0-0.1"
              />
              <path
                class="st0"
                d="M844.1,310.3c6,19,10.9,38,14.8,57.6c-6,10.1-12.3,19.9-19,29.4l0.6-8.4l-2.8-7.8l-1.7-17.9l-0.7-12.9
				l-0.5-8.9l-3.3-3.4C836,328.9,840.1,319.7,844.1,310.3z"
              />
              <path
                class="st0"
                d="M840.9,301.4c1.1,2.9,2.1,5.9,3.2,8.9c-4,9.4-8.1,18.6-12.7,27.6l-1-1.1l0.7-6.7l0.9-5.6l1.4-6.5l-1.1-6.5
				l-0.5-3.6l5.1-5.3L840.9,301.4z"
              />
              <path
                class="st0"
                d="M1072.1,245.9l2.1-0.5l1.7,6l-3.2,5.1l-2.9,1.1h-1.2c-2.1-6.7-4.2-13.4-6.4-20c0.1-0.5,0.1-1.1,0.2-1.6
				l4.8,1.6l2.6,6.9L1072.1,245.9z"
              />
              <path
                class="st0"
                d="M1079.5,186.3l3.7,6.3l-0.6,7.7l-6.8,3.7l-0.2,3.4l-2.4,4.2l-7.5-1.8l-0.8,2.3l3.4,4.9l13.7,4.9l1.3-3.1
				l1.2-5.1l3.8,6.7l-1.2,8.5l-2.2,3.2l-9.9,2.4l-1.5-4.9l-7.5-1.4l-2.9,2.2c2.5-27.3,3.3-54.5,2.6-81.9l3.7,2.8
				c3.3,7.2,6.5,14.5,9.6,21.8L1079.5,186.3z"
              />
              <path
                class="st0"
                d="M1065.5,148.7c0.7,27.4-0.1,54.6-2.6,81.9c-0.2,1.8-0.3,3.6-0.5,5.4c-0.1,0.5-0.1,1.1-0.2,1.6
				c-0.2-0.6-0.4-1.2-0.7-1.9c-0.2-0.5-0.4-1-0.5-1.5c-10.2-28.2-21.7-55.5-34.7-82.5c-0.1-3.6-0.3-7.3-0.5-10.9l5.9,4.5l15.7-1.2
				l11.6-0.5L1065.5,148.7z"
              />
              <path
                class="st0"
                d="M1061,234.2c0.2,0.5,0.4,1,0.5,1.5c0.2,0.6,0.4,1.2,0.7,1.9c-1.1,8.4-2.3,16.8-3.6,25.1l0,0
				c-1.5,9-3.1,18-5,26.9c-0.1,0.3-0.1,0.7-0.2,1c-2.2,10.7-4.7,21.3-7.5,31.9c-6.4-27.9-14.3-55.1-23.8-82.1
				c3.7-29.5,4.7-59.1,4.2-88.8C1039.3,178.7,1050.8,206.1,1061,234.2z"
              />
              <path
                class="st0"
                d="M1022.1,240.5c-10.3-27.5-21.8-54-34.9-80.2c-0.7-28.2-3.2-56-7.2-83.9l4.2,0.4
				c15.2,24.4,29.2,49.2,42.1,74.9C1026.8,181.4,1025.8,211,1022.1,240.5z"
              />
              <path
                class="st0"
                d="M1005.5,320.2c7.4-26.3,12.8-52.6,16.6-79.6c-10.3-27.5-21.8-54-34.9-80.2c0.1,28-1.6,55.4-5.2,83.2
				C991.2,268.7,999.1,294,1005.5,320.2z"
              />
              <path
                class="st0"
                d="M987.2,160.3c0.1,28-1.6,55.4-5.2,83.2c-10-25.6-21.3-50.2-33.9-74.6c-0.3-23.8-1.8-47.3-4.6-70.8l0.4-0.3
				l3.5-6.2C961.6,113.9,974.9,136.7,987.2,160.3z"
              />
              <path
                class="st0"
                d="M965.1,317.7c7.7-24.3,12.9-49.1,16.8-74.2c-10-25.6-21.3-50.2-33.9-74.6c-0.4,26-2,51.8-6.2,77.5
				C950.9,269.8,958.6,293.4,965.1,317.7z"
              />
              <path
                class="st0"
                d="M948.1,168.8c-0.4,26-2,51.8-6.2,77.5c-9.8-23.7-20.7-46.5-32.9-69c0.1-15.1-0.3-30-1.3-44.9l0.7-6.7l1-8.6
				l6.2-3.1C927.1,131.9,937.9,150.1,948.1,168.8z"
              />
              <path
                class="st0"
                d="M924.8,315.2c7.3-22.7,12.9-45.4,17.1-68.9c-9.8-23.7-20.7-46.5-32.9-69c-0.9,24.3-3.2,48-7.2,71.9
				C910.6,271,918.3,292.8,924.8,315.2z"
              />
              <path
                class="st0"
                d="M909,177.4c-0.9,24.3-3.2,48-7.2,71.9c-7.1-16.1-14.7-31.8-22.9-47.2l6.2,3.8l2.2,6.7l0.7,4.3l7.1-5.6l1.7-8
				l-0.5-8.9l-5.7-2.1l-3-8.8l1.4-13.7l5.9-0.9l0.1-15.5C899.7,161.3,904.4,169.3,909,177.4z"
              />
              <path
                class="st0"
                d="M901.7,249.3c-4.4,21.6-9.6,42.8-17.3,63.5c-1.5-4.9-3-9.8-4.6-14.6l0,0l-0.4-6.8l-3-9.6l3.3-4.8l3.3-7.6
				l3.5,2.8l1.2,1.8l0.1,1.8l6.9-3l3.2-9.8l-3.5,1.2l-6.5,0.4l-9.5-0.7l-4.6-9.1l-4.4-14.4l0.8-13.1l4.7-1l-2.7-15.5l6.2-8.9
				l0.4,0.2C887,217.5,894.7,233.2,901.7,249.3z"
              />
              <path
                class="st0"
                d="M842.4,301l3,5.9c-0.4,1.2-0.9,2.3-1.3,3.5c-1-3-2.1-5.9-3.2-8.9L842.4,301z"
              />
              <path
                class="st0"
                d="M853.8,269.2l3,2.1c-1.1,3.8-2.1,7.6-3.3,11.3l0.6-2.9l-2.6-3.3l-3.1,0.2l-6.6,0.4l-1.6-2l7.5-5.5
				L853.8,269.2z"
              />
              <path
                class="st0"
                d="M859.3,261.8c-0.4,1.6-0.8,3.2-1.2,4.8l-2.1-2.7l-0.9-3.6L859.3,261.8z"
              />
            </g>
            <g>
              <path
                class="st0"
                d="M1002,595.8c3.5-13.2,6.5-26.5,9.2-40.1c0,0,0-0.1,0-0.2c-17,19.9-35.1,38.5-54.4,56.2
				c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-4.8,14.8-10,29.2-15.8,43.5L953,659l12.2-3.2c0.2-0.1,0.3-0.3,0.5-0.4
				c8.6-7.1,17.1-14.4,25.5-22.1c2.6-8.1,5-16.2,7.3-24.3c0-0.1,0.1-0.2,0.1-0.4"
              />
              <path
                class="st0"
                d="M974.3,538.7c-4.2,24.9-10.4,49.3-17.7,73.4c-13.5,11.9-27.3,23.3-41.7,34.1l-10.1-6.3
				c6.4-16.1,12.1-32.3,17.2-48.9C940.6,574.5,957.8,557.2,974.3,538.7z"
              />
              <path
                class="st0"
                d="M937.4,521.7c-3.8,23.5-8.9,46.4-15.3,69.3c-10.9,9.2-22,18.1-33.4,26.5l-10.5-17.8
				c3.4-9.8,6.5-19.7,9.4-29.8C905.1,554.7,921.6,538.8,937.4,521.7z"
              />
              <path
                class="st0"
                d="M900.5,504.8c-3.1,22-7.4,43.5-13,65.1c-6.1,5-12.2,9.8-18.5,14.5l-6.7-9.6l2.9-3.8l15.9-7.7l-2.2-13.6
				l-4.4-6.6l-0.5-9.5l0.4-3.1C883.3,522.2,892,513.6,900.5,504.8z"
              />
              <path
                class="st0"
                d="M863.5,487.9c-0.5,4.2-1,8.4-1.6,12.5l-0.4-0.3l-5-5.5C858.9,492.4,861.2,490.1,863.5,487.9z"
              />
            </g>
          </g>
        </g>{" "}
      </svg>
    </div>
  );
}

export default AnimeBackground;
