import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Fintech Marvels - The Quantum Leap",
      description:
        "Dive into the awe-inspiring world of fintech marvels, featuring discussions on decentralized finance (DeFi), NFTs, quantum computing, and the metaverse's impact on financial services.",
      image: <S2 />,
    },
    {
      title: "Sustainable Finance Ecosystems - Nurturing Tomorrow's Wealth",
      description:
        "Explore the thriving ecosystems of sustainable finance, including green bonds, carbon markets, impact investing, and the role of fintech in fostering environmental and social responsibility (ESG).",
      image: <S3 />,
    },
    {
      title: "The Data-Driven Revolution - Transforming Insight Into Excellence",
      description:
        "Immerse yourself in the data-driven revolution, covering AI-powered analytics, real-time decision-making, cybersecurity in the digital age, and blockchain's role in redefining trust and transparency.",
      image: <S8 />,
    },
  ];
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-700 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2.5rem" : "1.5rem" }}
              >
                <b>
                  Igniting the Financial Revolution:
                  <br /> Fintech, AI, Cybersecurity, Sustainability, Data- Driven Transformation and
                  the Future of Payments
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                Get ready for the 24th edition of Finnovex Qatar, where financial services
                innovation and excellence converge with a fresh twist. This year, we're turning up
                the creativity and diving deep into the latest fintech trends. The theme for this
                summit is "Igniting the Financial Revolution," and it's all about riding the waves
                of fintech, embracing sustainability, AI, cybersecurity, harnessing the power of
                data-driven transformation and the future of payments .
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#d2a822" }}>Objective </b>
                <br />
                <br />
                The 24th edition of Finnovex Qatar 2024 is more than just a summit; it's an
                immersive experience. Our aim is to provide a dynamic platform for industry
                disruptors, visionaries, and innovators to explore, engage, and elevate the
                financial landscape in Qatar by staying ahead of the curve.
                <br />
                <br />
                Mark your Calendars for Finnovex Qatar's 25th edition promises to be a dynamic and
                engaging event that will empower financial professionals, policymakers, and
                stakeholders to navigate the evolving financial landscape, embrace innovation, and
                drive excellence in the financial services sector in Qatar with 200+ attendees. Join
                us for two days of insightful discussions, networking opportunities, and the chance
                to shape the future of Qatar's finance.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              {content.map((s, index) => (
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={0.5 + index / 8}
                >
                  <div className="pt-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                    <div className="container-fluid row pr-0">
                      <Col className="align-self-center" xs={3}>
                        {s.image}
                      </Col>
                      <Col>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-0"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </Col>
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
