// reactstrap components
import React, { useEffect } from "react";

import Container from "reactstrap/lib/Container";

import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from "reactstrap";

function Event({ text, location }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "SBBaw7jAwXM",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
    }
  }, []);

  return (
    <div className="section pt-0" style={{ backgroundColor: !size ? "#000" : "transparent" }}>
      {size && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: -100,
            marginLeft: "auto",
            marginRight: "auto",
            pointerEvents: "none",
            // marginTop: "3.5rem",
          }}
        >
          <div
            id="bgndVideo1"
            className="section player"
            style={{ background: "transparent" }}
          ></div>
        </div>
      )}
      {size && <div className="overlay-primary" />}
      <div className="page-header" style={{ background: "transparent" }} data-parallax={true}>
        <Container>
          <Row className="text-700 justify-content-center" style={{ marginTop: "2rem" }}>
            <Col lg={6} className="mb-5">
              <h1 className="text-700 text-white">Why Attend?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Experience an exclusive chance to network and engage with 150+ senior leaders
                spanning diverse industries. Our meticulously planned agenda aims to address your
                pressing business challenges, propelling your industry ahead through innovative
                ideas and strategies. Join our summit to access invaluable resources that promise
                to:
              </h5>
              <Button
                href="/delegates/3"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>Learn More</b>
              </Button>
            </Col>
            <Col lg={6}>
              <h1 className="text-700 text-white">Why Sponsor?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Boost Your Business Development Looking to fuel your business development for 2023
                and beyond? Join our event to engage directly with senior buyers from the financial
                services industry in Saudi Arabia. Showcase your expertise and establish valuable
                connections with decision-makers actively seeking new FinTech solutions.
              </h5>
              <Button
                href="/partners"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>BECOME A SPONSOR</b>
              </Button>
            </Col>
            <Col lg={6}></Col>

            <Col lg={6}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Event;
