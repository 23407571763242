import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Vision 2030's Financial Landscape",
      description:
        "Analysing the progress made in reshaping finance to align with Vision 2030's objectives.",
      image: <S2 />,
    },
    {
      title: "Fintech & Innovation",
      description:
        "Exploring how technology fosters a more inclusive, sustainable, and resilient financial system.",
      image: <S3 />,
    },
    {
      title: "Data and Analytics",
      description:
        "Unveiling the power of data, emerging trends, and blockchain's impact on trust and analytics.",
      image: <S8 />,
    },
    {
      title: "Payment Regulatory Framework",
      description:
        "Examining the role of governments in promoting inclusive finance through regulation.",
      image: <S1 />,
    },
  ];
  const content1 = [
    {
      title: "Insights",
      description:
        "The future is a direct reflection of the pressing demands and dreams of the present, particularly within the domain of digital banking and financial services. As we sculpt the technological horizon of tomorrow, our visionary leaders, well-versed in the pulse of public preferences, will unveil their insights during the upcoming conference. Anticipate captivating panel discussions, intimate fireside conversations, in-depth deliberations, and live demonstrations, all meticulously tailored to address significant themes.",
    },
    {
      title: "Networking",
      description:
        "To foster innovative thinking, ideas must be given room to breathe, openly deliberated upon, polished through constructive discussions, and wholeheartedly welcomed. Finnovex presents an invaluable opportunity to engage with influential figures, seasoned industry leaders, forward-thinking visionaries, and trailblazers. Make the most of networking intermissions, receptions, and specially designated areas to interact with experts in the field, delve into the world of financial technology advancements, and form productive partnerships.",
    },
    {
      title: "Spotlight",
      description:
        "Connecting with key decision-makers can be a daunting task, but Finnovex offers a streamlined solution. We bring together top digital banking leaders in a single, easily accessible platform. This is your opportunity to showcase your organisation to a broader audience, reaching beyond conference attendees. With a strong online presence, email campaigns, and active social media channels, we ensure your message is heard. Get ready for personalised networking, exclusive sessions, premium sponsor demonstrations, thought-provoking discussions, engaging presentations, and fireside chats with pioneers of innovation.",
    },
    {
      title: "Roundtables",
      description:
        "Enhance your networking at our exclusive roundtable breakout sessions. These intimate gatherings, limited to about 10 participants, spark dynamic idea exchanges. Led by experienced moderators, these sessions explore compelling topics from multiple perspectives, creating a seamless fusion of networking and knowledge sharing, enriching your conference experience.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={11}>
                {" "}
                <span className="text-white"></span>
                <h2 className="text-400 text-white">
                  Igniting the Financial Revolution:
                  <br /> Fintech, AI, Cybersecurity, Sustainability, Data- Driven Transformation and
                  the Future of Payments
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  Get ready for the 24th edition of Finnovex Qatar, where financial services
                  innovation and excellence converge with a fresh twist. This year, we're turning up
                  the creativity and diving deep into the latest fintech trends. The theme for this
                  summit is "Igniting the Financial Revolution," and it's all about riding the waves
                  of fintech, embracing sustainability, AI, cybersecurity, harnessing the power of
                  data-driven transformation and the future of payments .
                  <br />
                  <br />
                  <b style={{ fontSize: 25, color: "#d2a822" }}>Objective </b>
                  <br />
                  <br />
                  The 24th edition of Finnovex Qatar 2024 is more than just a summit; it's an
                  immersive experience. Our aim is to provide a dynamic platform for industry
                  disruptors, visionaries, and innovators to explore, engage, and elevate the
                  financial landscape in Qatar by staying ahead of the curve.
                  <br />
                  <br />
                  Mark your Calendars for Finnovex Qatar's 25th edition promises to be a dynamic and
                  engaging event that will empower financial professionals, policymakers, and
                  stakeholders to navigate the evolving financial landscape, embrace innovation, and
                  drive excellence in the financial services sector in Qatar with 200+ attendees.
                  Join us for two days of insightful discussions, networking opportunities, and the
                  chance to shape the future of Qatar's finance.
                  <br />
                  <br />
                </h5>
              </Col>
              <Col lg={6} className="mt-5 pr-0">
                {content.map((s, index) => (
                  <ScrollAnimation
                    animateIn={"fadeInUp"}
                    animateOnce={true}
                    duration={0.5 + index / 8}
                  >
                    <div className="pt-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                      <div className="container-fluid row pr-0">
                        <Col className="align-self-center" xs={3}>
                          {s.image}
                        </Col>
                        <Col>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "18px" : "14px",
                            }}
                            className="text-700 mt-0"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "16px" : "12px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </Col>
                      </div>
                    </div>
                  </ScrollAnimation>
                ))}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Innovate Now, Prosper Forever: Shaping the Future of Banking and Finance
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 mb-5">
                  <Col lg={6} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-0 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6}>
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "BO2A9941.jpg",
  "DSC_0119.jpg",
  "DSC_0154.jpg",
  "DSC_0168.jpg",
  "DSC_0178.jpg",
  "DSC_0195.jpg",
  "DSC_0332.jpg",
  "DSC_0511.jpg",
  "DSC_9715.jpg",
  "DSC_9854.jpg",
  "DSC_9885.jpg",
];
